import React from "react"
import GlobalStyle from "../components/layout/GlobalStyle"
import styled from "styled-components"
import HeaderWithPhoto from "../components/layout/header/HeaderWithPhoto"
import PageButton from "../components/layout/buttons/PageButton"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const Header1 = styled.h1`
  color: white;
  font-size: 55px;
  line-height: 50px;
  font-weight: 800;
  text-shadow: 2px 2px 4px #0000008f;
`

const InnerLink = styled(Link)`
  font-weight: 800;
  color: orange;
`

const Header2 = styled.h2`
  color: white;
  font-size: 40px;
  line-height: 30px;
  font-weight: 500;
  text-shadow: 2px 2px 4px #0000008f;
  letter-spacing: 0.96px;
`

const Header3 = styled.h3`
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  margin: 20px;
  letter-spacing: 0.96px;
`

const Container = styled.div`
  margin: 50px 70px;
`

const TextArea = styled.p`
  margin: 20px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px;
`
const Muut = ({ data }) => {
  const imageData = data.file.childImageSharp.fluid
  return (
    <GlobalStyle>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {
            "Itämeren risteilyt Helsingistä ja Turusta - Katso parhaat tarjoukset!"
          }
        </title>
        <meta
          name="description"
          content={
            "Haluatko lähteä risteilemään Tukholmaan, Ahvenanmaalle, Pietariin tai vaikka Riikaan. Katso täältä parhaat risteilytarjoukset miniloman toteuttamiseen. Lue lisää."
          }
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4632341699833963"
          crossorigin="anonymous"
        ></script>
        <html lang="fi" />
      </Helmet>
      <HeaderWithPhoto image={imageData} alt="muut risteilyt">
        <Header1>
          Tutustu myös muihin matkoihin ja löydä vaihtelua Tallinnaan
        </Header1>
        <Header2>Minkälainen matka sinua voisi kiinnostaa?</Header2>
        <ButtonContainer>
          <PageButton
            text={"Tukholman matka"}
            url={"/muut/ruotsin-risteily/"}
          ></PageButton>
          <PageButton
            text={"Riikan risteily"}
            url={"/muut/riikan-risteily/"}
          ></PageButton>
          <PageButton
            text={"Pietarin risteily"}
            url={"/muut/pietarin-risteily/"}
          ></PageButton>
          <PageButton
            text={"Pärnun matkat"}
            url={"/muut/parnun-matkat/"}
          ></PageButton>
          <PageButton
            text={"Piknik risteilyt"}
            url={"/muut/piknik-risteily/"}
          ></PageButton>
          <PageButton
            text={"Romanttinen viikonloppu"}
            url={"/muut/romanttinen-viikonloppu/"}
          ></PageButton>
        </ButtonContainer>
      </HeaderWithPhoto>
      <Container>
        <Header3>Tukholman matka</Header3>
        <TextArea>
          Viking Linen Turun satama sijaitsee Linnansatamassa.
          <InnerLink to={"/muut/ruotsin-risteily/turusta/"}>
            Laivoja Turusta kohti Tukholmaa
          </InnerLink>{" "}
          lähtee normaaliaikataulun mukaan kahdesti päivässä. Viking Linellä
          Turku-Tukholma-välillä liikennöi kaksi laivaa: Amorella ja Viking
          Grace. Amorella lähtee joka aamu Turusta kello 8:45 ja palaa takaisin
          seuraavana aamuna 7:35.
        </TextArea>
        <TextArea>
          Amorellan matkassa voit valita joko perinteisen vuorokausiristeilyn
          Turusta Tukholmaan tai Päivä Tukholmassa -risteilyn, jolla saat
          viettää kokonaisen päivän Tukholmassa esimerkiksi shoppailun,
          nähtävyyksien tai kaupunkikiertelyn parissa. Amorellan viikkoon kuuluu
          erityisiä teemapäiviä: maanantaisin kuunnellaan inspiroivia Elä tässä
          hetkessä -luentoja, tiistaisin bingoillaan Janne Porkan ja Esa
          Silanderin johdolla, keskiviikkoisin tanssitaan tähtiartistien
          tahdissa ja sunnuntaisin perheen pienimpiä viihdyttää Ville Viking.
        </TextArea>
        <TextArea>
          Viking Linen laivat Mariella ja Gabriella liikennöivät{" "}
          <InnerLink to={"/muut/ruotsin-risteily/turusta/"}>
            Helsingin Katajanokan satamasta Tukholman Stadsgårdeniin
          </InnerLink>{" "}
          kahdesti päivässä. Risteilyt Helsingistä Tukholmaan sisältävät paljon
          laatuaikaa laivalla ja maissa; lähtö joka ilta Mariellalla tai
          Gabriellalla, päivä Tukholmassa, paluu Katajanokan satamaan seuraavana
          aamupäivänä. M/S Mariella liikennöi parillisina viikkoina
          maanantaisin, keskiviikkoisin, perjantaisin ja sunnuntaisin,
          parittomina viikkoina vuorotellaan päinvastoin.
        </TextArea>
        <TextArea>
          Mariella tarjoaa mielekästä tekemistä ja viihdettä kaikenikäisille,
          lähditpä matkaan sitten lasten, työkavereiden tai puolison kanssa.
          Pienten matkustajien iloksi Mariellalta löytyy pallomerellä ja
          kiipeilyhuoneella varustettu leikkihuone kannelta 6, nuorten huone
          PS4-peleineen kannelta 8 ja elokuvia katselemaan ja värikynillä
          taiteilemaan lapset pääsevät kannen 8 Kids-huoneessa.
        </TextArea>
        <Header3>Pärnun matkat</Header3>
        <TextArea>
          <InnerLink to={"/muut/parnun-matkat//"}>Pärnun</InnerLink>{" "}
          hallinnolliseen kaupunkiin nykyään kuuluva historiallinen Pärnu
          sijaitsee Lounais-Virossa Pärnumaan maakunnassa, Itämeren ja Pärnujoen
          rannalla. Viron pääkaupungista, eli Tallinnasta, on Pärnuun matkaa
          reilusti yli 100 kilometriä. Matka on kuitenkin helppo taittaa
          vaikkapa omalla autolla, jonka voi tuoda Suomesta käsin laivalla
          Viroon. Tallinnasta kulkee Pärnuun myös rautatie, jossa tosin ei kulje
          vielä moneen vuoteen matkustajaliikennettä. Pärnuun kulkee kuitenkin
          Valtatie 4, joka on Viron pohjois-etelä-suuntaisia pääväyliä. Pärnu ei
          ole asukasmäärältään järin suuri kaupunki, mutta siitä huolimatta
          siellä käy varsinkin kesäisin paljon turisteja. Laajaa aluetta
          Pärnusta hallitsee ranta, ja juuri sen pitkistä hiekkarannoista Pärnu
          tunnetaankin.
        </TextArea>
        <TextArea>
          Pärnuun matkustetaan paljon etenkin kesää viettämään ja suuri osa
          siellä vierailevista turisteista on suomalaisia. Mutta miksi Pärnuun
          kannattaa ylipäätään matkustaa? Pärnuun houkuttelevat monet asiat,
          kuten sen upeat hiekkarannat ja hemmottelevat kylpylät. Tärkeä syy
          suomalaisten matkustamiseen kohteeseen ovat varmasti myös se, että
          sinne on pienen etäisyyden ja vilkkaan laivaliikenteen ansiosta
          helppoa ja melko edullista matkustaa. Sinne onkin helppo lähteä
          itsenäisesti ilman valmista matkapakettia oppaineen. Pärnuun tehdään
          myös valmismatkoja, joten itsenäisesti matkustaminen ei tietenkään ole
          pakollista. Lisäksi Pärnuun matkustaessa on helppo pysähtyä
          vierailemaan Viron muissakin kaupungeissa, kuten esimerkiksi sen
          pääkaupungissa Tallinnassa.
        </TextArea>
        <TextArea>
          Pärnu tarjoaa kesäisin Baltian rannikon unohtumatonta tunnelmaa ja
          etenkin silloin siellä riittää runsaasti tekemistä ja kokemista.
          Varmasti suosituimpiin kuuluvat aktiviteetit Pärnun lomalla ovat sen
          valkeilla ja laajoilla hiekkarannoilla auringon ottaminen, uiminen ja
          muut rannan aktiviteetit. Lisäksi kaunis merenrantapromenadi sopii
          hyvin kävelyyn ja pyöräilyyn ja yksinkertaisesti kesästä ja lomasta
          nauttimiseen. Mikäli pelkkä löhöily ei kiinnosta, Pärnun vesissä voi
          kokeilla vaikkapa purjehtimista, vesihiihtoa, melomista Pärnujokea
          pitkin tai monia muita harrastusmahdollisuuksia. Kesä on myös
          loistavaa aikaa tutustua nähtävyyksiin kuten kaupungin kauniisiin
          kirkkoihin tai kaupungin markkinoihin ja tapahtumiin. Pärnussa riittää
          kesäisin paljon tekemistä ja koettavaa. Eihän sitä aivan turhaan
          kutsuta Viron kesäpääkaupungiksi.
        </TextArea>
        <Header3>Piknik risteily</Header3>
        <TextArea>
          Helsinki on erittäin suosittu satamakaupunki, ja vuosittain sen läpi
          kulkee melkein 12 miljoonaa matkustajaa. Matkustajaliikennettä löytyy
          aivan Helsingin ydinkeskustasta sijaitsevista Eteläsatamasta ja
          Länsisatamasta sekä Itä-Helsingistä löytyvästä Vuosaaren satamasta.
          Laivayhteyksiä löytyy esimerkiksi Tallinnaan, Tukholmaan, Pietariin ja
          Travemündeen, mutta kesäkaudella kohdemäärä nousee erikoisristeilyjen
          takia.{" "}
          <InnerLink to={"/muut/piknik-risteily//"}>
            Piknik-risteilyjä
          </InnerLink>{" "}
          järjestävät suuret laivayhtiöt Viking Line ja Eckerö Line.
        </TextArea>
        <TextArea>
          Viking Linen piknik-risteilyt kuuluvat yhtiön tuotevalikoimaan aina
          kesäkausina, tarkemmin 16.6.–12.8. Noin kuuden tunnin mittaisten
          risteilyjen aikana matkustajat pääsevät nauttimaan upeista
          merimaisemista sekä Vikingin kuuluisasta The Buffet -buffetpöydästä
          kaikkine ruokajuomineen. Voit koostaa itsellesi kolmen ruokalajin
          lounaan noutopöydästä ja valita ruokajuomaksi olutta, viiniä ja
          virvoitusjuomia. Lisäksi kahvi ja tee sisältyvät hintaan.
        </TextArea>
        <TextArea>
          Turun satama on niin ikään erittäin suosittu risteilysatama ja samalla
          Suomen vanhin toimiva satama. Sen kautta kulkee vuosittain noin viisi
          miljoonaa ulkomaan matkustajaa. Satamasta lähtee päivittäin
          matkustajalaivoja Tukholmaan, Maarianhaminaan ja Långnäsiin. Turun
          saaristo on myös yksi kauneimmista Suomen nähtävyyksistä, joka on
          tehnyt Turusta ihanteellisen lähtöpaikan piknik-risteilyille.
        </TextArea>
        <TextArea>
          Matkustajat pääsevät Viking Linen kyydissä päiväksi saaristoon.
          Risteilyt hoidetaan kahdella laivalla: Amorellalla ja Viking Gracella.
          Niiden aikana pysähdytään vähäksi aikaa Maarianhaminassa, jossa
          matkustajat vaihtavat laivaa ja palaavat sillä takaisin Turkuun.
          Upeisiin saaristomaisemiin pääsee ympäri vuoden, ja etenkin
          jouluristeilyt ainutlaatuisine joulupöytineen ovat olleet erittäin
          suosittuja jo monen vuoden ajan.
        </TextArea>
        <Header3>Riikan risteily</Header3>
        <TextArea>
          Kun Tallinna on jo nähty moneen otteeseen, kannattaa vaihtaa suunta
          etelämmäksi. Lyhyen matkan päässä Tallinnasta on Baltian Pariisiksi
          kutsuttu <InnerLink to={"/muut/riikan-risteily//"}>Riika</InnerLink>{" "}
          sekä Jurmalan suosittu kylpyläkaupunki. Riika on 660 000 asukkaallaan
          koko Baltian suurin kaupunki. Itämeren rannalla Väinäjoen (Daugavan)
          suulla 312 km Tallinnasta sijaitseva Riika on historiallinen Unescon
          maailmanperintökohde.
        </TextArea>
        <TextArea>
          Riian tunnetuin ja suosituin nähtävyys on sen upea vanha kaupunki,
          Vecriga, jonka jugend- ja art noveau -tyylisessä arkkitehtuurissa
          riittää katseltavaa. Vanhan kaupungin uniikki keskustori houkuttelee
          lomailijoita ottamaan valokuvia kuuluisan Mustapäiden talon edessä.
          Riika on klassinen kaupunkilomakohde, ja vanhassa kaupungissa
          tunnelmoinnin lisäksi lomalla kannattaa shoppailla ja käydä monissa
          kaupungin mahtavissa ravintoloissa.
        </TextArea>
        <TextArea>
          Lyhyiden välimatkojen Viro ja sen pääkaupunki Tallinna ovat oiva
          omatoiminen automatkailukohde vuoden ympäri. Ajamalla auton laivaan
          voit yhdistää monta lomaa yhdeksi paketiksi. Jos mielii pidemmälle
          kuin Tallinna, Via Baltica (E67) -tie vie Tallinnasta aina Varsovaan
          asti. Riikaan on helppo matkustaa sitä pitkin niin omalla autolla kuin
          bussillakin.
        </TextArea>
        <TextArea>
          Laivaliikenne sujuu vaivatta, ja lähtöjä on Helsingin ja Tallinnan
          välillä päivittäin kymmeniä. Tallink Silja, Viking Line ja Eckerö Line
          tarjoavat monia eri reittimatkoja ja risteilyjä moneen eri makuun, ja
          niitä voi räätälöidä yhteen oman aikataulun mukaan. Lähtöjä on koko
          päivän kello 07.30 ja 23.30 välisinä aikoina Helsingin molemmista
          satamista.
        </TextArea>
        <Header3>Pietarin risteily</Header3>
        <TextArea>
          St. Peter Line on vuonna 2010 perustettu varustamo, joka on
          venäläisessä omistuksessa. Helsingin ja Pietarin välillä varustamo
          aloitti liikennöinnin huhtikuussa 2010, jolloin käytössä oli M/S
          Princess Maria. St. Peter Line on risteillyt huhtikuusta 2011 lähtien
          myös{" "}
          <InnerLink to={"/muut/pietarin-risteily//"}>
            Helsinki-Tukholma-Tallinna-Pietari-väliä
          </InnerLink>
          . Ennen St. Peter Linen toiminnan alkamista Helsingin ja Pietarin
          välillä liikennöimistä yrittivät niin Silja Line, Tallink kuin Stella
          Linekin, mutta kaikki huonolla menestyksellä. Viimeiseksi mainittu
          ajautui konkurssiin vain kaksi kuukautta toimittuaan. St. Peter
          Linella toiminta on sen sijaan pyörinyt mallikkaasti ja säännöllistä
          toimintaa on pystytty jatkamaan jo yli kahdeksan vuoden ajan.
        </TextArea>
        <TextArea>
          Risteilyt Helsingin ja Pietarin välillä liikennöidään M/S Princess
          Anastasia -nimisellä matkustaja-autolautalla. Se on valmistunut vuonna
          1986, ja se kulki Viking Linen alaisuudessa vuoteen 1993 asti, jolloin
          alus tunnettiin nimellä M/S Olympia. Laiva rakennettiin Turussa,
          Wärtsilän Pernon telakalla, josta se valmistumisen jälkeen siirrettiin
          vesille Helsingin ja Tukholman väliselle reitille. Aluksen omistaja
          joutui taloudellisiin vaikeuksiin, ja Olympiaksi tuolloin kutsuttu
          laiva myytiin P&O Ferriesille, joka nimesi sen Pride of Bilbaoksi.
          Uuden omistajansa aikana alus seilasi Portsmouthista Bilbaoon ja
          Portsmouthista Cherbourgiin. Vuonna 2010 P&O Ferries päätti lopettaa
          reitit ja laiva myytiin St. Peter Linelle.
        </TextArea>
        <TextArea>
          Helsinki–Pietari-risteilylle lähtevällä on useita matkavaihtoehtoja.
          Suoraan St. Peter Linelta varaamalla voi valita hotellimatkan, Päivä
          Pietarissa -matkan tai reittimatkan. Päivä Pietarissa -risteilypaketti
          sisältää risteilyn, majoituksen laivalla valitussa hyttiluokassa,
          shuttle-bussikuljetukset satamasta Pietarin keskustaan ja takaisin
          sekä paluumatkan samassa hytissä. Shuttle-bussi on kätevin tapa
          siirtyä sataman ja keskustan välinen matka. Lähtö Helsingin
          Länsiterminaalista on kello 18 tai 19 ja laiva on Pietarissa
          seuraavana aamuna klo 9. Matkustajilla on päivä aikaa tutustua
          kaupunkiin, kunnes illalla klo 18 tai 19 Princess Anastasia lähtee
          takaisin kohti Helsinkiä. Laiva on Helsingin satamassa aamulla joko
          kello 7, 8 tai 11.30 riippuen lähtöpäivästä.
        </TextArea>
        <TextArea>
          Hotellipakettimatkoissa hintaan sisältyy laivamatkat
          Helsinki-Pietari-Helsinki valitussa hyttiluokassa, yöpymiset ja
          aamiaiset hotellissa Pietarissa, Shuttle-bussikuljetukset satamasta
          hotellille ja takaisin, viisumivapausliput sekä satama- ja
          polttoainemaksut. Parhaimmissa hyttiluokissa laivalla hintaan sisältyy
          myös deluxe-aamiainen. St. Peter Linen kautta voi varata kahden tai
          kolmen yön mittaisia hotellimatkoja, joissa lähtö tapahtuu illalla
          Helsingistä ja seuraavana aamuna voi aloittaa Pietariin tutustumisen.
          Shuttle-bussikuljetus vie satamasta suoraan hotellille, joten
          matkatavarat on helppo jättää sinne.
        </TextArea>
        <Header3>Romanttinen viikonloppu</Header3>
        <TextArea>
          Milloin viimeksi olitte rakkaasi kanssa kahden lomalla? Kun yhteisen
          loman tarve yllättää, kannattaa muistaa, että pieni irtiotto arjesta
          on mahdollista tehdä myös pienellä budjetilla ja nopealla
          aikataululla. Hyvä vaihtoehto romanttiselle hemmottelulomalle on
          tällöin kylpyläloma kotimaassa. Suomesta löytyy kymmeniä eri
          kylpylähotelleja ympäri maata, jotka tarjoavat asiakkailleen ylellisen
          kylpyläkokemuksen ja hemmotteluhoitojen lisäksi myös lukuisia muita
          aktiviteettimahdollisuuksia.
        </TextArea>
        <TextArea>
          Oman rakkaan kanssa voi kohteesta riippuen vaeltaa, lasketella,
          vierailla museoissa, huuhtoa kultaa tai vain nauttia hyvästä ruoasta
          ja tunnelmasta ja rentoutua. Matkaa varten ei kannata kuitenkaan tehdä
          liikaa suunnitelmia, vaan pieni impulsiivisuus voi tällaisella
          romanttisella pikku karkureissulla olla vain hyväksi.
        </TextArea>
        <TextArea>
          Asennoitukaa siis yhteiseen matkaanne iloisin ja avoimin mielin ja
          hemmotelkaa ennen kaikkea toisianne. Näin on onnistunut{" "}
          <InnerLink to={"/muut/romanttinen-viikonloppu//"}>
            hemmotteluloma
          </InnerLink>{" "}
          taattu.
        </TextArea>
      </Container>
    </GlobalStyle>
  )
}

export const query = graphql`
  query {
    allProductsJson(filter: { city: { eq: "Tallinna" } }) {
      edges {
        node {
          agency
          auto
          benefits
          city
          country
          departures
          desc
          id
          innerType
          length
          link
          name
          price
          return
          ship
          transport
          type
          shipImg
          visitTime
        }
      }
    }
    file(base: { eq: "tallinna.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Muut
